import { Route, Routes, useLocation } from "react-router-dom";
import React, { createContext, useState } from 'react';
import { AnimatePresence } from "framer-motion";
import Login from "./Login";
import Home from "./Home";
import ForgotPass from "./ForgotPass";
import NoAccess from "./NoAccess";
import NotFound from "./NotFound";
import Welcome from "../components/ui/Welcome";
import AdditionalInfoScreen from "./AdditionalInfoScreen";
import UploadScreen from "./UploadScreen";
import PhotoScreen from "./PhotoScreen";
import Schedular from "./Schedular";
import TaskSchedular from "./TaskCalendar/Schedular";
import AcuitySchedularScreen from "./AcuitySchedularScreen";
import MessageScreen from "./Messages";
import MessageDetails from "./MessageDetails";
import ProvideInfo from "./ProvideAdditionalInfo";
import MenuPage from "../pages/inner/MenuPage";
import BlogPage from "./Blog"
import BMICalculator from "./BMICalculator";
import PeriodTracker from './PeriodTracker'
import TaskCalendar from './TaskCalendar';
import ProfilePage from "./ProfilePage";
import MyClinic from "./MyClinic";
import VideoLibrary from "./VideoLibrary";
import TravelForm from "./TravelDonor/TravelForm";
import TravelDetails from "./TravelDonor/TravelDetails";
import TravelInfo from "./TravelDonor/TravelInfo";
import TravelItenary from "./TravelDonor/TravelItenary";
import TestingKit from "./TestingKit";
import CompleteLab from "./CompleteLab";
import Appointment from "./Appointment";
import PsychologEvaluation from './Task/PsychologEvaluation';
import InfoSessionVideo from "./InfoSessionVideo";
import NotifyClinic from "./NotifyClinic";
import DocuSign from "./DocuSign";
import PhotoShoot from "./PhotoShoot";
import LogoutPage from "./LogoutPage";
import MenuTravelHandout from "./MenuTravelHandout";
import TravelHandoutTask from "./TravelHandoutTask";
import AustralianMessagingProgram from "./AustralianMessagingProgram";


const Pages = () => {
    const location = useLocation();
    return (
        <AnimatePresence mode="wait">
            <Routes key={location.pathname} location={location}>
                
                {/* Auth routes */}
                <Route path='/' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPass />} />
                <Route path='/no-access' element={<NoAccess />} />

                {/* Protected routes */}
                <Route path='/welcome' element={<Welcome />} />
                <Route path='/tutorial' element={<Welcome />} />
                <Route path='/home' element={<Home />}  />
                <Route path='/task-calendar' element={<TaskCalendar />} />
                <Route path='/home/additional-info' element={<AdditionalInfoScreen />} />
                <Route path='/home/upload' element={<UploadScreen />} />
                <Route path='/home/photo' element={<PhotoScreen />} />
                <Route path='/home/schedule' element={<Schedular />} />
                <Route path='/home/task-schedule' element={<TaskSchedular />} />
                <Route path='/acuity' element={<AcuitySchedularScreen/>}/>
                <Route path='/messages' element={<MessageScreen />} />
                <Route path='/messages/detail' element={<MessageDetails />} />
                <Route path='/home/provide-info' element={<ProvideInfo/>} />
                <Route path='/period-tracker' element={<PeriodTracker />} />
                <Route path="/home/testing-kit" element={<TestingKit />} />
                <Route path="/home/complete-lab" element={<CompleteLab />} />
                <Route path="/home/pcp-obgyn" element={<PsychologEvaluation />} />
                <Route path="/home/psychological-evaluation" element={<PsychologEvaluation />} />
                <Route path="/home/video-session" element={<InfoSessionVideo />} />
                <Route path="/home/notify-clinic" element={<NotifyClinic />} />
                <Route path="/home/docu-sign" element={<DocuSign />} />
                <Route path="/home/travel-program-handout" element={<TravelHandoutTask />} />
                <Route path='/home/australian-messaging' element={<AustralianMessagingProgram />} />

                {/* inner pages */}
                <Route path='/faqs' element={<MenuPage />} />
                <Route path='/photoguide' element={<PhotoShoot />} />
                <Route path='/eligibilty' element={<MenuPage/>} />
                <Route path='/egg-process' element={<MenuPage/>} />
                <Route path='/id-non-id' element={<MenuPage/>} />
                <Route path='/travel-program' element={<MenuPage/>} />
                <Route path='/zika-zones' element={<MenuPage/>} />
                <Route path='/bmi-calculator' element={<BMICalculator/>} />
                <Route path='/blogs' element={<BlogPage/>} />
                <Route path='/locations' element={<MenuPage />} />
                <Route path='/pwa-instructions' element={<MenuPage />} />
                <Route path='/refer-a-friend' element={<MenuPage/>} />
                <Route path='/profile' element={<ProfilePage/>} />
                <Route path='/day-of-retrieval-tips' element={<MenuPage/>} />
                <Route path='/my-clinic' element={<MyClinic/>}/>
                <Route path="/video-library" element={<VideoLibrary/>} />
                <Route path="/travel-form" element={<TravelForm />} />
                <Route path="/travel-details" element={<TravelDetails />} />
                <Route path="/appointment" element={<Appointment />} />
                <Route path="/travel-info" element={<TravelInfo />} />
                <Route path="/travel-iternary" element={<TravelItenary />} />
                <Route path="/travel-handout" element={<MenuTravelHandout />} />
                <Route path='/australia-program' element={<MenuPage />} />
                <Route path="/logout" element={<LogoutPage/> } />

                {/* Catch all */}
                    <Route path="*" element={<NotFound />} />
                
            </Routes>
        </AnimatePresence>
    );
}

export default Pages;
