export const TASK_STATUS = {
    TODO: 0,
    NEED_APPROVAL: 1,
    COMPLETE: 2,
    REJECTED: 3
}

export const TASK_TYPE = {
    AD_HOC: 1,
    DIRECT_LINK: 2,
    ADDITIONAL_INFO: 3,
    SCHEDULE: 4,
    UPLOAD: 5,
    MISC: 6,
    INFO_VIDEO: 9,
    APPOINTMENT: 10,
    LAB: 11,
    DOCU_SIGN: 7,
    TRAVEL_DONOR: 8,
    TRAVEL_HANDOUT: 12,
    AUSTRALIAN_PROGRAM: 13
}

export const TASK_SUB_TYPE = {
    SCHEDULE_MEDICAL_SCREENING_3: 2,
}

export const APPLICANT_REJECTED_STATUS = {
    ID: 8,
    StatusText: "Rejected",
}

export const DATE_TIME_FORMAT = {
    MESSAGES: "hh:mm a"
}
