import styles from "./MenuPage.module.scss";
import { Accordion, Button } from "react-bootstrap";
import Layout from "../../components/ui/Layout";
import Loader from "../../components/ui/Loader";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useEffect,useRef,useState} from "react";
import { debounce } from "lodash";
import * as Actions from "../state/menupage/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import PageTitle from "../components/PageTitle";
import parse from 'html-react-parser';

const MenuPage = () => {
    const location = useLocation();
	let { state } = useLocation();
    const [menuName,setMenuName] = useState("");
    const dispatch = useDispatch();
    const {
        menuPageData: {  pageInfo,addReferal },
    } = useSelector((state) => state);
    const { response: postReferal, isLoading: referalLoading } = addReferal || {};
    const { response: pageDetails, isLoading: pageLoading } = pageInfo || {};
    const [pageData,setPageData] = useState("");
    const [pageSlug,setPageSlug] = useState("");
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [referalForm, setReferalForm] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [addressData,setAddressData] = useState([]);
    const [referalCode,setReferalCode] = useState("");
    const emailconsentRef = useRef();
    const phoneconsentRef = useRef();

    const stateSource = [
        'Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia',
        'Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana',
        'Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska',
        'Nevada','New Hampshire','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma',
        'Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah',
        'Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming','Armed Forces Americas','Armed Forces Europe',
        'Armed Forces Pacific'
    ];

    useEffect(() => {
        document.title = 'Menu Page';
        let pagename = location?.pathname.split('/').slice(1)[0];
        pagename = pagename.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        setPageSlug(pagename);
        dispatch(Actions.menuPageClear());
        fetchPageDetails(pagename);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setMenuName("");
        setPageData([]);
        if (typeof pageDetails !== "undefined" && pageDetails) {
            if(pageDetails?.title === "Referral Form")
            {
                setMenuName("Refer A Friend To Become An Egg Donor");
            } else if (pageSlug === "pwa-instructions") {
                setMenuName("Add to Mobile Device");
            } else {
                setMenuName(pageDetails?.pageTitle);
            }
            setPageData(pageDetails);
        }
    },[pageDetails]);

    useEffect(() => {
        if (typeof postReferal !== "undefined" && postReferal) {
            setReferalCode(postReferal);
            dispatch(Actions.referalRequestClear());
		}
    }, [postReferal]);// eslint-disable-line react-hooks/exhaustive-deps

    const getContentHeight = () => {
        const mainElement = document.getElementsByClassName("app");
        let headerElement = document.querySelector('.homePageTitle');
        let footerElement = document.querySelector('.footerElement');

        return mainElement[0]?.offsetHeight - (headerElement?.clientHeight + footerElement?.clientHeight + 5);
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setScrollBarHeight(getContentHeight());
            window.console.log('debounce - ', getContentHeight());
        }, 300);

        // set scroll window height
        setScrollBarHeight(getContentHeight());
        window.addEventListener("resize", debouncedHandleResize);

        // cleanup listener
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    }, [scrollbarHeight, menuName]);


    const fetchPageDetails = (pagename) => {
        if(pagename === 'refer-a-friend') {
            pagename = "refer-friend" // update slug to match WP
        }
        let params = {page:pagename};
        dispatch(Actions.menuPageRequest(params));
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const onchangeEmail = event => {
        if (!isValidEmail(event.target.value)) {
            setFormErrors({ ...formErrors, email: "invalid" })
        } else {
            setFormErrors(null);
        }

        setReferalForm({ ...referalForm, email: event.target.value });
    };

    const slugify = (str) =>
            str.toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '');

    const handleSubmit = (event) => {
        
        setFormSubmitted(true);

        if(formErrors!=null)
        {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }

        let consent = {};
        if(phoneconsentRef?.current?.checked)
        {
            consent["phoneconsent"] = phoneconsentRef?.current?.defaultValue
        }
        if(emailconsentRef?.current?.checked)
        {
            consent["emailconsent"] = emailconsentRef?.current?.defaultValue
        }

        const formData = new FormData();
        formData.append("formFields",JSON.stringify(referalForm));
        formData.append("formAddress",JSON.stringify(addressData));
        formData.append("formConsent",JSON.stringify(consent));
        dispatch(Actions.addReferalRequest(formData));
        event.preventDefault();
        event.stopPropagation();
    }

    const onChangeAddress =(e,label,index) => {
        let addressCopy = [...addressData];
        addressCopy[index] = e.target.value;
        setAddressData(addressCopy);
    }   

    return (
        <>
            {(pageLoading || referalLoading) && <Loader />}
            <Layout showHeader="false">
                <PageTitle
                    title={menuName}
                    headerClass="homePageTitle"
                    linkTo={state?.prevPage ? state?.prevPage : "/home"}
                    linkState={{ menu: state?.menu, taskId: state?.taskId }}
                />
                <div id="pagecontent" className={styles.pageContent}>
                    <Scrollbars
                        height={scrollbarHeight}
                        autoHeight
                        autoHeightMax={scrollbarHeight}
                        className="scroll-container"
                    >
                        {
                            pageSlug === "faqs" &&
                            <div className={`${styles.faqPage} px-3 pt-5 contentContainer`}>
                                {
                                    pageData?.page && Object.keys(pageData?.page).map((content,i) => {
                                        return <div key={content}  style={{marginBottom:"30px"}}>
                                            <Accordion
                                                className="pb-1 px-0"
                                            >
                                            <h3 className={`${styles.faqMainTitle} mb-3 text-center`}>{pageData.page[content]['faq_title']}</h3>
                                            {pageData?.page[content]['faq_content'] && pageData?.page[content]['faq_content'].map((msgs,j) =>{
                                                return <div key={j} style={{marginBottom:"10px"}}>
                                                {
                                                    <Accordion.Item
                                                        className="border-bottom"
                                                        eventKey={j}
                                                    >
                                                        <Accordion.Header>
                                                            <h5>{msgs['ac_stitle']}</h5>
                                                        </Accordion.Header>
                                                        <Accordion.Body className={styles.faqBody}>
                                                            <p>
                                                                    {parse(msgs['ac_content'])}
                                                            </p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                }
                                                </div>
                                            })}
                                            </Accordion>
                                        </div>
                                    })
                                }
                            </div>
                        }
                        {
                            (pageSlug === "eligibilty") &&
                            <div className={`${styles.pageContent} px-3 py-5 contentContainer`}>
                                {
                                    pageData?.page && Object.keys(pageData?.page).map((content,i) => {
                                        return <div key={content}  style={{marginBottom:"30px"}}>
                                            <h3 className={`${styles.contentPgTitle} mb-3 text-center`}>{parse(pageData.page[content]['title'])}</h3>
                                            <p>{parse(pageData?.page[content]['content'])}</p>
                                        </div>
                                    })
                                }
                            </div>
                        }
                        {
                            (pageSlug === "id-non-id") &&
                            <div className={`${styles.pageContent} px-3 py-5 contentContainer`}>
                                {
                                    pageData?.page && Object.keys(pageData?.page).map((content,i) => {
                                        return <div key={content}  style={{marginBottom:"30px"}}>
                                            {
                                                content === "0" &&
                                                <>
                                                    <h2 className={`${styles.contentPgTitle} mb-3`}>{parse(pageData.page[content]['title'])}</h2>
                                                    <p>
                                                        {parse(pageData?.page[content]['content'])}
                                                    </p>
                                                </>
                                            }
                                            {
                                                content === "1" &&
                                                <>
                                                    <h2 className={`${styles.contentPgTitle} mb-3 text-center`}>{parse(pageData.page[content]['title'])}</h2>
                                                    <h4>{parse(pageData?.page[content]['left_headline'])}</h4>
                                                    <p>
                                                        {parse(pageData?.page[content]['left_content'])}
                                                    </p>
                                                    <h4>{parse(pageData?.page[content]['right_headline'])}</h4>
                                                    <p>{parse(pageData?.page[content]['right_content'])}</p>
                                                </>
                                            }

                                        </div>
                                    })
                                }
                            </div>
                        }
                        {
                            (pageSlug === "pwa-instructions" || pageSlug === "day-of-retrieval-tips" || pageSlug === "locations" || pageSlug === "travel-program" || pageSlug === "zika-zones" || pageSlug === "egg-process" || pageSlug === "australia-program") &&
                            <div className={`${styles.pageContent} px-3 py-5 contentContainer`}>
                                {
                                    pageData?.page && Object.keys(pageData?.page).map((content,i) => {
                                        return <div key={content}  style={{marginBottom:"30px"}}>
                                            {
                                                <>
                                                   {parse(pageData?.page[content]['content'])}
                                                </>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        }
                        {
                            (pageSlug === "refer-a-friend" && (referalCode == null || referalCode === "" || referalCode === -1)) &&
                            <form className={`${styles.pageContent} px-3 py-5 contentContainer`} onSubmit={handleSubmit}>
                                <h3 style={{textAlign:"center"}}>Referral Form</h3>
                                {
                                    referalCode === -1 &&
                                    <p style={{color:"#f00",textAlign:"center"}}>
                                        Error on sending data, please try again.
                                    </p>
                                }
                                {
                                    pageData!==undefined && pageData?.fields!==undefined && Object.keys(pageData?.fields).map((content,i) => {
                                        return <div key={content} >
                                            {
                                                pageData?.fields[content].type === "name" && pageData?.fields[content]?.inputs.map((nameFields, i) => {
                                                return <div key={i} className={styles.nameFormGroup}>
                                                    {
                                                        <>
                                                            {
                                                                nameFields.id === "1.3" &&
                                                                 <>
                                                                    <div className={`${styles.gf_fields} ${formSubmitted === true && !referalForm.firstname ? styles.required : ""}`}>
                                                                        <label>{nameFields.customLabel}</label>
                                                                        <input required className={styles.formControl} type="text" name="firstname" value={referalForm.firstname || ""}
                                                                            onChange={e => setReferalForm({ ...referalForm, firstname: e.target.value })}
                                                                        />
                                                                        <p className={styles.requiredElement}>Firstname Required</p>
                                                                    </div>
                                                                 </>
                                                            }
                                                            {
                                                                nameFields.id === "1.6" &&
                                                                 <>
                                                                    <div className={`${styles.gf_fields} ${formSubmitted === true && !referalForm.lastname ? styles.required : ""}`}>
                                                                        <label>{nameFields.customLabel}</label>
                                                                        <input required className={styles.formControl} type="text" name="lastname" value={referalForm.lastname || ""}
                                                                            onChange={e => setReferalForm({ ...referalForm, lastname: e.target.value })}
                                                                        />
                                                                        <p className={styles.requiredElement}>Lastname Required</p>
                                                                    </div>
                                                                 </>
                                                            }
                                                        </>
                                                    }
                                                    </div>
                                                })
                                            }
                                            {
                                                pageData?.fields[content].type === "email" &&
                                                <>
                                                    <div className={styles.emailFormGroup}>
                                                        <div className={`${styles.gf_fields} ${formSubmitted === true && (formErrors!=null && formErrors.email === "invalid") ? styles.invalid : ""} ${formSubmitted === true && !referalForm.email ? styles.required : ""}`}>
                                                            <label>{pageData?.fields[content].label}</label>
                                                            <input className={styles.formControl} required type="text" name="email" value={referalForm.email || ""}
                                                                onChange={onchangeEmail}
                                                            />
                                                            <p className={styles.requiredElement}>Email Required</p>
                                                            <p className={styles.invalidElement}>Invalid Email</p>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                pageData?.fields[content].type === "phone" &&
                                                <>
                                                    <div className={styles.phoneFormGroup}>
                                                        <div className={`${styles.gf_fields} ${formSubmitted === true && !referalForm.phone ? styles.required : ""}`}>
                                                            <label>{pageData?.fields[content].label}</label>
                                                            <input className={styles.formControl} required type="text" name="phone" value={referalForm.phone || ""}
                                                                onChange={e => setReferalForm({ ...referalForm, phone: e.target.value })}
                                                            />
                                                            <p className={styles.requiredElement}>Phone Required</p>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                pageData?.fields[content].type === "address" && pageData?.fields[content].inputs.map((addressFields, i) => {
                                                    let required = addressFields.id !== (9.2).toString(); // 9.2 - id for Apartment#

                                                    return <div key={i} className={styles.nameFormGroup}>
                                                        {
                                                            addressFields.isHidden === false  &&
                                                            <div className={styles.addressFormGroup}>
                                                                <div className={`${styles.gf_fields} ${formSubmitted === true && (typeof addressData[i] === 'undefined' || (typeof addressData[i] != 'undefined' && addressData[i]) === "") ? styles.required : ""}`}>
                                                                    {<label>{addressFields.customLabel}</label>}
                                                                    <input className={styles.formControl} required={required} type="text" name={addressFields.label}
                                                                    onChange={e => onChangeAddress(e,slugify(addressFields.label),i)}
                                                                    />
                                                                    <p className={styles.requiredElement}>Required</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            addressFields.id==="9.4" &&
                                                            <div className={styles.addressFormGroup}>
                                                                <div className={`${styles.gf_fields} ${formSubmitted === true && !referalForm.state ? styles.required : ""}`}>
                                                                    {<label>{addressFields.customLabel}</label>}
                                                                    <select required className={styles.formControl}
                                                                    onChange={e => setReferalForm({ ...referalForm, state: e.target.value })}
                                                                    >
                                                                        <option></option>
                                                                        {
                                                                            stateSource.map((options,i)=>{
                                                                               return <option key={i} value={options}>{options}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                    <p className={styles.requiredElement}>State Required</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        </div>
                                                    })
                                            }
                                            {
                                                pageData?.fields[content].type === "select" && pageData?.fields[content].id === 10 &&
                                                <>
                                                    <div className={styles.hereusFormGroup}>
                                                        <div className={styles.gf_fields}>
                                                            <label>{pageData?.fields[content].label}</label>
                                                            <select className={styles.formControl}
                                                                onChange={e => setReferalForm({ ...referalForm, iam: e.target.value })}>
                                                                {
                                                                    pageData?.fields[content].choices.map((options,i) => {
                                                                        return  <option key={i} value={options.value}>{options.text}</option>

                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                pageData?.fields[content].type === "select" && pageData?.fields[content].id === 4 &&
                                                <>
                                                    <div className={styles.hereusFormGroup}>
                                                        <div className={styles.gf_fields}>
                                                            <label>{pageData?.fields[content].label}</label>
                                                            <select className={styles.formControl}
                                                                onChange={e => setReferalForm({ ...referalForm, hearedus: e.target.value })}>
                                                                {
                                                                    pageData?.fields[content].choices.map((options,i) => {
                                                                        return  <option key={i} value={options.value}>{options.text}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {
                                                            referalForm?.hearedus === "Other" &&
                                                            <div className={`${styles.gf_fields} `}>
                                                                <label>If "Other" Please Explain</label>
                                                                <input className={styles.formControl} type="text" name="hearedus_other" value={referalForm.hearedus_other || ""}
                                                                    onChange={e => setReferalForm({ ...referalForm, hearedus_other: e.target.value })}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            }
                                            {
                                                pageData?.fields[content].type === "checkbox" && pageData?.fields[content].id === "12" &&
                                                <>
                                                    <div className={styles.consentFormGroup} style={{marginTop:"20px"}}>
                                                        <div className={styles.gf_fields}>
                                                            <input type="checkbox" value={pageData?.fields[content].choices[0].value}
                                                                ref={emailconsentRef}
                                                                defaultChecked={true}
                                                                onChange={e => setReferalForm({ ...referalForm, emailconsent: e.target.value })}
                                                            />
                                                            <p>
                                                                {parse(pageData?.fields[content].choices[0].text)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                pageData?.fields[content].type === "checkbox" && pageData?.fields[content].id === "14" &&
                                                <>
                                                    <div className={styles.consentFormGroup} style={{marginTop:"20px"}}>
                                                        <div className={styles.gf_fields}>
                                                            <input type="checkbox" value={pageData?.fields[content].choices[0].value}
                                                                ref={phoneconsentRef}
                                                                defaultChecked={true}
                                                                onChange={e => setReferalForm({ ...referalForm, phoneconsent: e.target.value })}
                                                            />
                                                            <p>
                                                                {parse(pageData?.fields[content].choices[0].text)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    })
                                }
                                {
                                    pageData!==undefined && pageData?.fields!==undefined &&
                                    <div style={{textAlign:"center"}} className={styles.referalSubmitButton}>
                                        <Button type="submit">
                                            {pageData?.button?.text}
                                        </Button>
                                    </div>
                                }
                            </form>
                        }
                    </Scrollbars>
                    {
                        (pageSlug === "refer-a-friend" && referalCode !== "") &&
                        <div className={styles.referalThank}>
                            <h3 style={{fontSize:"50px"}}>Thank you</h3>
                            <p>
                                Thanks for entering our referral rewards program! Share the referral code below with your friends.
                            </p>
                            <p style={{fontSize:"20px",margin:"20px 0",fontWeight:"bold"}}>
                                {referalCode}
                            </p>
                        </div>
                    }
                </div>
            </Layout>
        </>

    );
}

export default MenuPage;
