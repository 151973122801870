import { useEffect,useState} from "react";
import styles from "./Appointment.module.scss";
import Layout from "../components/ui/Layout";
import { useLocation,useNavigate } from 'react-router-dom';
import { BsXLg } from "react-icons/bs";
import Loader from "../components/ui/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "./state/appointment/actions";
import * as menuActions from "./state/menupage/actions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Endpoints } from "../core/networking";
import { MimeType } from "../utils";
import { createPortal } from "react-dom";
import TaskDescription from "./components/TaskDescription";
import PageTitle from "./components/PageTitle";
import { GetPhotoInstructions } from '../services/Common';

const Appointment = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [scrollbarHeight, setScrollBarHeight] = useState(window.screen.availHeight);
    const {
        loginData: { loginUser },
        appointmentData: { submitAppointment, getTaskDocument, downloadTaskFile, getAppointmentInterviewData },
        menuPageData: { clinicInfo }
    } = useSelector((state) => state);
    const { response: loginVal } = loginUser || {};
    const { response: completeTask, isLoading: completeLoading } = submitAppointment || {};
    const { response: documentTask, isLoading: documentLoading } = getTaskDocument || {};
    const { response: downloadFile, isLoading: downloadFileLoading } = downloadTaskFile || {};
    const { response: appointmentInterviewData, isLoading: appointmentInfoLoading } = getAppointmentInterviewData || {};
    const { response: clinicVal, isLoading: clinicLoading } = clinicInfo || {};

    const [taskInfo, setTaskInfo] = useState([]);
    const [what,setWhat] = useState("");
    const [where,setWhere] = useState("");
    const [when, setWhen] = useState("");
    const [interviewFor, setInterviewFor] = useState("");
    const [taskDocument, setTaskDocument] = useState([]);
    const [fileName, setFileName] = useState("");
    const [fileInfo, setFileInfo] = useState(null);
    const [msgCoordinatorModal, setMsgCoordinatorModal] = useState(false);
    const [coOrdinator, setcoOrdinator] = useState([]);
    const [photoInstructions, setPhotoInstructions] = useState(false);

    useEffect(() => {
        // set scroll window height
        const mainElement = document.getElementsByClassName("app");

        setScrollBarHeight(mainElement[0]?.offsetHeight  - 190);
        window.addEventListener("resize", function () {
            setScrollBarHeight(mainElement[0]?.offsetHeight  - 190);
        });
    }, [scrollbarHeight]);

    useEffect(() => {
        document.title = 'Appointment Scheduled';
        setMsgCoordinatorModal(false);
        setFileInfo(null);
        setFileName("");
        setTaskInfo(location?.state?.taskInfo);

        dispatch(Actions.getTaskDocumentClear());
        dispatch(Actions.sendToApproveClear());
        dispatch(Actions.fileDownloadClear());
        dispatch(Actions.getAppointmentSchedInterviewClear());
    }, [dispatch, location?.state?.taskInfo]);

    const submitTaskComplete = () => {
        if (taskInfo?.ID) {
            const taskcompleteEndpoint =
                Endpoints.HOME_COMPLETED_DONOR + taskInfo?.ID;
            let params = {
                endpoint: taskcompleteEndpoint,
                method: "PUT",
            };
            dispatch(Actions.sendToApproveRequest(params));
        }
    }

    useEffect(() => {
        if(taskInfo){
            let param;
            let taskEndPoint;

            if(taskInfo?.TaskSubType)
            {
                taskInfo.SubTaskType = taskInfo?.TaskSubType;
            }
            if(taskInfo?.AssociatedID)
            {
                taskInfo.ID = taskInfo?.AssociatedID;
            }

            if (taskInfo?.SubTaskType === 5 || taskInfo?.SubTaskType === 1 || taskInfo?.SubTaskType === 6) {
                taskEndPoint = Endpoints.APPOINTMENT_SCHEDULED + taskInfo?.ID;
            } else if (taskInfo?.SubTaskType === 2 || taskInfo?.SubTaskType === 9) {
                taskEndPoint = Endpoints.APPOINTMENT_SCHEDULED_CLINIC + taskInfo?.ID;
            } else if (taskInfo?.SubTaskType === 7 || taskInfo?.SubTaskType === 8) {
                taskEndPoint = Endpoints.APPOINTMENT_SCHEDULE_INTERVIEW + taskInfo?.ID;
            } else if (taskInfo?.SubTaskType === 4 || taskInfo?.SubTaskType === 3) {
                taskEndPoint = Endpoints.APPOINTMENT_SCHEDULE_CLINIC_ENTITY + taskInfo?.ID;
            } else if (taskInfo?.SubTaskType === 8) {
                taskEndPoint = "";
            }

            param = { endpoint: taskEndPoint, method: "GET" };
            dispatch(Actions.getAppointmentSchedInterview(param));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskInfo]);

    useEffect(() => {
        if (typeof clinicVal !== "undefined" && clinicVal) {

            let clinicDetails = clinicVal;
            let dmaClinicCordinatorPhone = "";

            if (clinicVal?.dmaMyClinicCoordinators?.length > 0) {
                window.console.log(clinicVal?.dmaMyClinicCoordinators)
                let contactPhoneCordinator = clinicVal?.dmaMyClinicCoordinators.find(coOrd => coOrd['clinicContactPhone']);
                if (contactPhoneCordinator) {
                    dmaClinicCordinatorPhone = contactPhoneCordinator.clinicContactPhone;
                }
            }
            clinicDetails.dmaClinicCordinatorPhone = dmaClinicCordinatorPhone;

            setcoOrdinator(clinicDetails);
        }
    }, [clinicVal]);

    const sendMessageToCoordinator = () => {
        if (coOrdinator && coOrdinator?.dmaMyClinicCoordinators?.length > 0) {
            let coOrdinatorList = [];

            for (let i = 0; i < coOrdinator.dmaMyClinicCoordinators.length; i++) {
                coOrdinatorList.push({
                    ID: coOrdinator.dmaMyClinicCoordinators[i].clinicCoordinatorID,
                    Name: coOrdinator.dmaMyClinicCoordinators[i].clinicCoordinatorName,
                    selected: true
                });
            }

            if (coOrdinatorList && coOrdinatorList?.length > 0) {
                navigate("/messages", {
                    state: {
                        newMessage: true,
                        receipients: coOrdinatorList
                    },
                });
            }
            
        }
    }

    useEffect(() => {

            if (appointmentInterviewData) {
                if (taskInfo?.SubTaskType === 7 || taskInfo?.SubTaskType === 8)
                {
                    setWhat("Interview");
                    setWhere(appointmentInterviewData?.interviewType);
                    setWhen(appointmentInterviewData?.appointmentDateTime);
                    setInterviewFor(appointmentInterviewData?.interviewer);

                    //setWhat("Interview");
                    let when = appointmentInterviewData?.interviewType;
                    if(when === "Skype")
                    {
                        when = when + " - ID:" + appointmentInterviewData?.skypeUsername;
                    }
                    setWhere(when);
                    setWhen(appointmentInterviewData?.appointmentDateTime);
                    setInterviewFor(appointmentInterviewData?.interviewer);
                }
                else if (taskInfo?.SubTaskType === 4)
                {
                    setWhat("Photo Shoot");
                    setWhere(appointmentInterviewData?.clinicEntityType);
                    setWhen(appointmentInterviewData?.appointmentDateTime);
                    setInterviewFor(appointmentInterviewData?.name);
                    setPhotoInstructions(true);
                }
                else if (taskInfo?.SubTaskType === 3 )
                {
                    setWhat("Pyschological Evaluation");
                    setWhere(appointmentInterviewData?.clinicEntityType);
                    setWhen(appointmentInterviewData?.appointmentDateTime);
                    setInterviewFor(appointmentInterviewData?.name);
                }
                else if (taskInfo?.SubTaskType === 2 || taskInfo?.SubTaskType === 9)
                {
                    let applicantID = loginVal?.data?.applicant_id;
                    dispatch(menuActions.myClinicGetClear());

                    if (applicantID) {
                        let myclinicEndpoint = Endpoints.MY_CLINIC_GET_DATA + applicantID;
                        let param = { endpoint: myclinicEndpoint, method: "GET" };
                        dispatch(menuActions.myClinicGetRequest(param));
                    }
                    if (taskInfo?.SubTaskType === 2)
                        setWhat("Medical Screening");
                    else
                        setWhat("Physical Exam");
                    //setWhere("Medical Screening");
                    setWhen(appointmentInterviewData?.appointmentDateAndTime);
                    setInterviewFor(appointmentInterviewData?.name);
                }
                else if (taskInfo?.SubTaskType === 5 || taskInfo?.SubTaskType === 6){

                    let docTaskEndPoint = Endpoints.GET_TASK_DOCUMENT + taskInfo?.ID;
                    let docParam = { endpoint: docTaskEndPoint, method: "GET" };
                    dispatch(Actions.getTaskDocument(docParam));

                    setWhat(appointmentInterviewData?.appointmentType);
                    if (taskInfo?.SubTaskType === 5)
                        setWhere("Labcorp");
                    if (taskInfo?.SubTaskType === 6)
                        setWhere("Infectious Disease");
                    setWhen(appointmentInterviewData?.appointmentDateTime);
                    setInterviewFor(appointmentInterviewData?.name);
                }
                else if (taskInfo?.SubTaskType === 1) {
                    setWhat(appointmentInterviewData?.appointmentType);
                    setWhere("Personal Doctor Office");
                    setWhen(appointmentInterviewData?.appointmentDateTime);
                    setInterviewFor("");
                }

            }
    }, [appointmentInterviewData, taskInfo]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (
            typeof downloadFile !== 'undefined' &&
            downloadFile !== null &&
            downloadFile && fileName
        ) {
          
            let binaryString = window.atob(downloadFile);
            // window.console.log(binaryString)
            let binaryLen = binaryString.length;
            let bytes = new Uint8Array(binaryLen);

            for (let i = 0; i < binaryLen; i++) {
                let ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }

            let blob = new Blob([bytes], { type: MimeType(fileInfo) });
            let link = URL.createObjectURL(blob);

            dispatch(Actions.fileDownloadClear());

            const a = document.createElement('a');
            a.href = link;
            a.download = fileName; // Specify the file name
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Clean up
            URL.revokeObjectURL(link);
        }
    }, [downloadFile]);// eslint-disable-line react-hooks/exhaustive-deps

    const downloadAttachment = async (file) => {
        window.console.log(file);
        const taskEndPoint = file.URL;
        setFileInfo(file);        
        setFileName(file.FileName);
        let params = {
            endpoint: taskEndPoint,
            mime: MimeType(file),
            method: "GET",
        };
       // window.console.log("----params---",params);
        dispatch(Actions.fileDownloadRequest(params));
    };

    useEffect(() => {
        if(documentTask)
        {
            setTaskDocument(documentTask);
        }
    }, [documentTask]);

    useEffect(() => {
        if (completeTask?.status === 200) {
            dispatch(Actions.sendToApproveClear());
            navigate(-1);
        }
    }, [completeTask]);// eslint-disable-line react-hooks/exhaustive-deps

    const renderTitle = () => {
        switch (true) {
            case taskInfo?.SubTaskType === 1:
            case taskInfo?.SubTaskType === 2:
            case taskInfo?.SubTaskType === 8:
            case taskInfo?.SubTaskType === 9:
                return taskInfo?.TaskName ?? taskInfo?.EventName;
            case taskInfo?.SubTaskType === 5:
                return "Lab Work Appointment";
            case taskInfo?.SubTaskType === 6:
                return "Infectious Diseases";
            case taskInfo?.SubTaskType === 7:
                return "Complete Appointment - Interview";
            case taskInfo?.SubTaskType === 4:
                return "Photo Shoot Appointment";
            case taskInfo?.SubTaskType === 3:
                return "Psychological Evaluation Appointment";
            default:
                return "Appointment";
        }
    }

    return (
        <>
            {(completeLoading || documentLoading || downloadFileLoading || appointmentInfoLoading || clinicLoading) && <Loader />}
            {
                msgCoordinatorModal && createPortal(
                    <div className={styles.doctorModalOverlay}>
                        <div className={`${styles.doctorModalContainer} position-absolute`} >
                            <BsXLg
                                onClick={() => setMsgCoordinatorModal(false)}
                                className={`${styles.doctorModalIcon} position-absolute`}
                            />
                            {
                                (coOrdinator?.dmaMyClinicCoordinators && coOrdinator?.dmaMyClinicCoordinators?.length > 0) ?
                                 <>
                                    {
                                        coOrdinator?.dmaClinicCordinatorPhone &&
                                        <>
                                                <span className={styles.content}>If your appointment is within 48 hours please call your coordinator</span>
                                                <span className={styles.largeContent}>{coOrdinator?.dmaClinicCordinatorPhone}</span>
                                        </>
                                    }
                                    <button className={((coOrdinator?.dmaMyClinicCoordinators && coOrdinator?.dmaMyClinicCoordinators?.length > 0) ? `${styles.roundedBackgroundButton}` : `${styles.roundedBackgroundButton} ${styles.disabledButton}`)} onClick={() => sendMessageToCoordinator()}>Message Coordinator</button>
                                </>
                                :
                                <>
                                        <p style={{fontSize:"20px",fontWeight:"bold",margin:"20px 0"} }>You do not have any coordinator assigned. Please contact FairFax Eggbank</p>
                                </>
                            }
                            
                        </div>
                    </div>,
                    document.querySelector('.app')
                )
            }
            <Layout showHeader="false">
                <div className={styles.stickyHeader}>
                    <PageTitle
                        title={renderTitle()}
                        headerClass="homePageTitle"
                        linkTo={state?.prevPage ? state?.prevPage : -1}
                        linkState={{ menu: state?.menu }}
                    />
                </div>
                <div className={`position-relative ${styles.appointmentContainer}`} id="appointmentContainer">
                        <Scrollbars
                            autoHeight
                            className="scroll-container"
                            autoHeightMax={scrollbarHeight}
                            style={{ marginBottom: "20px" }}
                        >
                            <div className={styles.appointmentcontent}>

                                <TaskDescription styles={`px-3 py-3 my-2`} taskDetails={taskInfo} />
                                <h4 style={{ marginTop: (taskInfo?.RejectReason || taskInfo?.AdHocDescription || taskInfo?.EventDescription) ? '0px' : '10px' }}>
                                        Appointment Scheduled
                                </h4>
                                {
                                    interviewFor &&
                                    <p className={styles.interviewFor }>for {interviewFor}</p>
                                }
                                <div className={styles.appointInfo}>
                                    <label>What:</label>&nbsp;<span>{what}</span>
                                </div>
                                <div className={styles.appointInfo}>
                                    <div style={{width: "100%"}}>
                                        <label>Where:</label>
                                            {
                                                ((taskInfo?.SubTaskType !== 2 && taskInfo?.SubTaskType !== 4 && taskInfo?.SubTaskType !== 9) ) ?
                                                <span>
                                                    {where}
                                                </span>
                                                :
                                                <>
                                                    {
                                                        taskInfo?.SubTaskType === 4  &&
                                                        <div className={styles.whereAddress}>
                                                            <address>
                                                                {/* for photo shoot the address is removed as per client requirement*/ }
                                                                <p>{appointmentInterviewData?.email}</p>
                                                                <p>{appointmentInterviewData?.phone}</p>
                                                            </address>
                                                        </div>
                                                    }

                                                    {
                                                        (taskInfo?.SubTaskType === 2 || taskInfo?.SubTaskType === 9) &&
                                                            <div className={`${styles.whereDoctorAddress}`}>
                                                                <address>
                                                                    {appointmentInterviewData?.clinicName && <p>{appointmentInterviewData?.clinicName}</p>}
                                                                    {appointmentInterviewData?.clinicAddress && <p>{appointmentInterviewData?.clinicAddress}</p>}
                                                                    {appointmentInterviewData?.clinicCity}&nbsp;{appointmentInterviewData?.clinicState}&nbsp;{appointmentInterviewData?.clinicZipCode}
                                                                    {appointmentInterviewData?.clinicPhone && <p>{appointmentInterviewData?.clinicPhone}</p>}
                                                                </address>
                                                            </div>
                                                    }
                                                </>
                                            }
                                    </div>
                                </div>
                            <div className={styles.appointInfo}>
                                <label>When:</label>&nbsp;<span style={{ float: "left", width :"70%"} }>{when}</span>
                                </div>
                            </div>
                        {
                            (taskInfo?.SubTaskType === 5 || taskInfo?.SubTaskType === 6) &&
                            <>
                                <div className={styles.attachmentLists}>
                                    {
                                        taskDocument != null && taskDocument.length > 0 &&
                                        <div className={styles.attachmentLists}>
                                            <span className={styles.attachmentHeading}>Documents</span>
                                            <ul>
                                                {
                                                    taskDocument.map(
                                                        (list, counter) => {
                                                            return (
                                                                <li className={styles.labAttachment} key={counter} onClick={async () => await downloadAttachment(list)} >
                                                                    <span>{list.FileName}</span>
                                                                </li>
                                                            );
                                                        }
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                                <div className={styles.taskCompletedButton}>
                                    <button onClick={() => submitTaskComplete()}>
                                        {
                                            taskInfo?.SubTaskType === 6 ?
                                                <span>Infectious Disease Completed</span>
                                                :
                                                <span>Lab Work Completed</span>
                                        }

                                    </button>
                                </div>
                            </>
                        }
                        {
                            photoInstructions &&
                            <div className={styles.appointmentPhotoIns}>
                              {
                                GetPhotoInstructions()              
                              }
                            </div>
                        }
                        {
                            (taskInfo?.SubTaskType === 7 || taskInfo?.SubTaskType === 8) &&
                            <p style={{ maxWidth: "80%", margin: "3% auto" }}> {/* display only for Complete interview and audio interview */}
                                If you need to reschedule or cancel your appointment, please use the link in your appointment confirmation email.
                            </p>
                        }
                        {  (taskInfo?.SubTaskType === 1 || taskInfo?.SubTaskType === 4 || taskInfo?.SubTaskType === 3 || taskInfo?.SubTaskType === 2 || taskInfo?.SubTaskType === 9) &&
                            <div className={styles.taskCompletedButton} style={{ marginTop: "3%" }}>
                                <button onClick={() => submitTaskComplete()}>Appointment Completed</button>
                            </div>                           
                        }

                        {
                            (taskInfo?.SubTaskType === 2 || taskInfo?.SubTaskType === 9) &&
                            <div className={styles.contactCoordinator}>
                                    <span>Need to reschedule, cancel or have questions?</span>
                                    <button className={styles.roundedBackgroundButton} onClick={() => setMsgCoordinatorModal(true)}>Contact Coordinator</button>
                            </div>
                        }

                    </Scrollbars>
                </div>
            </Layout>

        </>
    );
}

export default Appointment;
